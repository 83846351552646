import React from "react"
import { LWrap, CRecruitJumbotron, CSectTitle, CDefinition, CIntroduction } from "../../components/_index"
import MicroCmsContents from "./microcms_contents"
import RecruitEntryBtn from "./recruit_entry_btn"

const RecruitLayout = ({ detail, pagetitle }: { detail: any, pagetitle: string }) => {

  return (
    <>
      <CRecruitJumbotron data={{
        subTitle: detail.hotel.hotelname,
        title: detail.title,
        labels: [detail.employment[0], detail.location[0]],
        img: detail.eyecatch,

      }} />
      <div className="l_sect u_pt60">
        <LWrap size="small">
          <p className="u_fs16">{detail.description.split('\n').map((t: string) => (<>{t}<br /></>))}</p>
        </LWrap>
      </div>
      <section className="l_sect u_bgGray">
        <LWrap size="small">
          <CSectTitle title={{ ja: "募集要項"  }} />
          <MicroCmsContents data={[detail.contents]} />
          {detail.entrybtn && <RecruitEntryBtn data={detail} />}
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap size="small">
          <CSectTitle title={{ ja: "ホテルについて", en: "ABOUT HOTEL" }} />
          <CIntroduction
            img={detail.hotel.hotelimage}
            title={detail.hotel.hotelheadline}
            text={detail.hotel.hotelbody}
          />
          {detail.entrybtn && <RecruitEntryBtn data={detail} />}
        </LWrap>
      </section>
    </>
  )
}
export default RecruitLayout;
