import React from "react"
import {
  CBtnList
} from "../_index"



const RecruitEntryBtn = ({ data }: { data: any }) => {

  return (
    <form method="get" action="https://contact.royalparkhotels.co.jp/recruit/entry?_ifbs-recruit_entry_form=s1_Step1" target="_blank">
      <input type="hidden" name="_ifbs-recruit_entry_form" value="s1_Step1" />
      <input type="hidden" name="hid" value={`${data.hotel.id}`} />
      <input type="hidden" name="occupatione" value={`${data.title}`} />
      <input type="hidden" name="employment" value={`${data.employment[0]}`} />
      <CBtnList data={[
        {
          tag: "button",
          label: "エントリー",
          color: "bgBlack",
          link: {
            href: ""
          },
          icon: "blank"
        }
      ]} />
    </form>
  )

}
export default RecruitEntryBtn
